import React from "react"

import SEO from "../components/seo"
import StaticTextContainer from "../components/StaticTextContainer"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <StaticTextContainer
      title="Page Not Found"
      body="The page you're looking for doesn't exists"
    />
  </>
)

export default NotFoundPage
